/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  Container as MapDiv,
  NaverMap,
  Marker,
  useNavermaps,
} from 'react-naver-maps';
import { MarkerDatas } from './MarkerDatas';
import { isInquiryModalOpenState } from '../../recoil/atom';
import { useRecoilState } from 'recoil';

export function StoreMaps() {
  const [isModalOpen] = useRecoilState(isInquiryModalOpenState);
  const [map, setMap] = useState(null);
  const initialStores = MarkerDatas().map((store) => ({
    ...store,
    infoWindowContent: `<div>
                        <h3>이공커피 ${store.title}</h3>
                        <p>${store.address}</p>
                      </div>`,
  }));
  const [allStores, setAllStores] = useState(initialStores);
  const [filter, setFilter] = useState('');
  const [isListVisible, setIsListVisible] = useState(true);
  const navermaps = useNavermaps();
  const center = new navermaps.LatLng(37.50689, 126.9615308); // 흑석본점

  // 입력값 변경에 따른 이벤트 핸들러
  const handleInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setFilter(inputValue);

    const filteredStores = initialStores.filter(
      (store) =>
        store.title.toLowerCase().includes(inputValue) ||
        store.address.toLowerCase().includes(inputValue)
    );
    setAllStores(filteredStores);
  };

  return (
    <MapDiv
      style={{
        height: '600px',
      }}
      className="naver-map"
    >
      <NaverMap
        ref={setMap}
        defaultCenter={center}
        defaultZoom={13}
        zoomControl
        zoomControlOptions={{
          position: naver.maps.Position.RIGHT_BOTTOM,
          style: naver.maps.ZoomControlStyle.SMALL,
        }}
      >
        <div
          className="mapStore"
          style={{
            backgroundColor: isModalOpen ? 'rgba(0,0,0,0.5)' : '',
            zIndex: isModalOpen ? '-1' : '',
          }}
        >
          <div className="map-top">
            <input
              placeholder="지점명 혹은 주소를 입력하세요"
              value={filter}
              onChange={handleInputChange}
            />
            <img className="search" src="/img/searchIcon.png" alt="" />
            <img
              className="drop-down"
              src={isListVisible ? '/img/arrow_up.png' : '/img/arrow_down.png'}
              alt=""
              onClick={() => setIsListVisible(!isListVisible)}
            />
          </div>
          <div className={`map-list ${isListVisible ? 'visible' : ''}`}>
            {allStores.map((item) => (
              <div
                className="content"
                key={item.id}
                onClick={(e) => {
                  e.preventDefault();
                  if (map) {
                    map.panToBounds(item.location);
                  }
                }}
              >
                <div className="img">
                  <img src="/img/coffeeIcon1.png" alt="" />
                </div>
                <div>
                  <strong>{item.title}</strong>
                  <span>{item.address}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {allStores.map((store) => (
          <Marker
            key={store.id}
            position={{ lat: store.lat, lng: store.lng }}
            icon={{
              content: `
                <div class="marker"> 
                    <p>이공커피 ${store.title}</p>
                    <img src="/img/marker1.png" alt="marker" />
                </div>
              `,
              size: new naver.maps.Size(50, 100),
              anchor: new naver.maps.Point(68, 60),
            }}
          />
        ))}
      </NaverMap>
    </MapDiv>
  );
}
